import React from "react";

export interface TestimonialCardProps {
  logo: string;
  text: string;
  name: string;
  position: string;
  backgroundColor: string;
  textColor: string;
  avatarColor: string;
  avatar?: string;
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({
  logo,
  text,
  name,
  position,
  backgroundColor,
  textColor,
  avatarColor,
  avatar,
}) => {
  return (
    <div
      className={`w-full h-[500px] mx-auto mb-8 lg:p-24 p-16 rounded-xl ${backgroundColor} z-20`}
    >
      <div className="mb-8 text-start">
        <img src={logo} alt={`Logo of ${name}`} className="h-12" />
      </div>
      <p className={`${textColor} lg:text-xl text-lg mb-8`}>{text}</p>
      <div className="flex items-start">
        {avatar && (
          <div className="flex-shrink-0">
            <img className="w-12 h-12 rounded-full" src={avatar} alt={name} />
          </div>
        )}
        <div className={`ml-${avatar ? 4 : 0}`}>
          <div className={`text-base font-semibold ${avatarColor}`}>{name}</div>
          <div className={`text-sm ${avatarColor}`}>{position}</div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
