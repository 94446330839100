import React, { useState, useEffect } from "react";
import TestimonialCard, {
  type TestimonialCardProps,
} from "./TestimonialsCard.tsx";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

const testimonials: TestimonialCardProps[] = [
  {
    logo: "https://www.balder.se/sites/all/themes/custom/balder_theme/logo.png",
    text: "Leadcaller har hjälpt oss att få fler intresseanmälningar. Det är enkelt att använda och har varit effektivt för att generera leads för vår verksamhet. Jag skulle rekommendera det till andra i branschen.",
    name: "Filip Falfors",
    position: "Verksamhetsutvecklare",
    textColor: "text-deepPurple",
    backgroundColor: "bg-coralTone",
    avatarColor: "text-deepPurple",
  },
  {
    logo: "https://images.squarespace-cdn.com/content/v1/5f33c8fc22feb657e45562f9/1597234819307-6SVWX7VF9I3QCZ8Z4PLB/embassy_house.jpg?format=1500w",
    text: "Efter att ha använt Leadcaller i några år kan jag med säkerhet säga att den absolut bästa upplevelsen har varit reaktionerna från våra kunder. Leadcaller har alltid varit snabba på att svara och tillmötesgå våra behov, vilket har gett utmärkt kundsupport. Jag skulle varmt rekommendera Leadcaller till andra baserat på min positiva erfarenhet.",
    name: "Anders Nilsson",
    position: "Grundare",
    textColor: "text-purpleAccent",
    backgroundColor: "bg-pureWhite",
    avatarColor: "text-deepPurple",
  },
  {
    logo: "https://expandia.se/app/themes/careofhaus/dist/logo.svg",
    text: "Vi i Expandia Moduler AS är väldigt nöjda med Leadcaller. Nästan alla som tar kontakt med oss och som vi då ringer upp, blir extremt imponerade över den snabba återkopplingen. Jag är övertygad om att just den snabba återkopplingen ger ett bra första intryck och det i sin tur ger oss en bra start på ett eventuellt samarbete.",
    name: "Fredrik Björling",
    position: "Säljchef",
    backgroundColor: "bg-deepPurple",
    textColor: "text-coralTone",
    avatarColor: "text-pureWhite",
  },
  {
    logo: "https://rondellenmaskin.se/wp-content/uploads/2023/05/rondellenmaskin-vit-logo.png",
    text:
      "Vi har använt Leadcallers plugin på vår hemsida under 2022 med goda resultat. Att kunna återkoppla till en kund inom en minut är alltid mycket uppskattat och leder ofta till affärer.\n" +
      "Kan varmt rekommenderas!",
    name: "Anders Frisk",
    position: "Försäljningschef",
    textColor: "text-deepPurple",
    backgroundColor: "bg-coralTone",
    avatarColor: "text-deepPurple",
  },
  {
    logo: "https://verktygshopen.se/wp-content/uploads/2020/02/logo.svg",
    text: "Leadcaller är ett verktyg som har gjort en stor skillnad för oss på P & T Verktyg. Det har gett oss möjlighet att snabbt kontakta potentiella kunder så fort de visar intresse för våra produkter eller tjänster. Vi har sett en ökning i vår försäljning tack vare detta verktyg. Jag rekommenderar verkligen LeadCaller's funktion till andra företag som vill effektivisera sin kommunikation med potentiella kunder.",
    name: "Peter Salomonsson",
    position: "CEO",
    textColor: "text-purpleAccent",
    backgroundColor: "bg-pureWhite",
    avatarColor: "text-deepPurple",
  },
];

interface TestimonialsProps {
  title: string;
}

const Testimonials: React.FC<TestimonialsProps> = ({ title }) => {
  const [visibleIndex, setVisibleIndex] = useState(0);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const showLeftArrow = visibleIndex > 0;
  const showRightArrow =
    visibleIndex < testimonials.length - (isDesktop ? 2 : 1);

  const shiftRight = () => {
    setVisibleIndex((prevIndex) =>
      prevIndex < testimonials.length - (isDesktop ? 2 : 1)
        ? prevIndex + 1
        : prevIndex
    );
  };

  const shiftLeft = () => {
    setVisibleIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const hiddenCard =
    visibleIndex > 0 && isDesktop ? testimonials[visibleIndex - 1] : undefined;

  return (
    <div className="bg-backgroundBase lg:py-20 py-12">
        <div className="text-deepPurple text-left mx-auto px-20 xl:px-48">
            <h2 className="text-2xl lg:text-6xl font-bold lg:mb-5">{ title }</h2>
        </div>
      <div className="relative container mx-auto px-8 py-20 w-full">
        <div className="flex overflow-x-auto testimonial-wrapper snap-x snap-mandatory">
          <div
            className="flex transition-transform ease-in-out duration-500 snap-center"
            style={{
              gap: "24px",
            }}
          >
            {hiddenCard && (
              <div
                className="absolute left-0 top-28 transform -rotate-6 origin-bottom-left w-1/2"
                style={{ zIndex: 10 }}
              >
                <TestimonialCard {...hiddenCard} />
              </div>
            )}

            {testimonials
              .filter((_, index) =>
                isDesktop
                  ? index === visibleIndex || index === visibleIndex + 1
                  : index === visibleIndex
              )
              .map((testimonial, index) => (
                <TestimonialCard key={index} {...testimonial} />
              ))}
          </div>
        </div>
        {showLeftArrow && (
          <button
            onClick={shiftLeft}
            className="z-30 previous-testimonial absolute top-1/2 left-0 transform -translate-y-1/2 bg-purpleAccent text-white p-6 rounded-full focus:outline-none hover:opacity-90 transition duration-300"
          >
            <ArrowLeftIcon className="h-9 w-9 text-pureWhite" />
          </button>
        )}
        {showRightArrow && (
          <button
            onClick={shiftRight}
            className="z-30 next-testimonial absolute top-1/2 right-0 transform -translate-y-1/2 bg-purpleAccent text-white p-6 rounded-full focus:outline-none hover:opacity-90 transition duration-300"
          >
            <ArrowRightIcon className="h-9 w-9 text-pureWhite" />
          </button>
        )}
      </div>
    </div>
  );
};

export default Testimonials;
